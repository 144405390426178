import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { getCookieByName } from '../../helpers/cookieHelper';
import Footer from '../footer/Footer';
import GoogleTagManagerHelmet from '../googleTagManager/GoogleTagManagerHelmet';
import GoogleTagManagerIFrame from '../googleTagManager/GoogleTagManagerIFrame';
import './layout.scss';

const Layout = ({ children, showFooter = true, isPrivacyPolicy = false }) => {
    const [cookieConsent, setCookieConsent] = useState(false);

    useEffect(() => {
        var consentCookie = getCookieByName('refactor-cookie-consent');
        if (consentCookie === 'true') setCookieConsent(true);
    }, []);

    const cookieConsentComponent = isPrivacyPolicy ? null : (
        <CookieConsent
            location="none"
            buttonText="Accept all cookies"
            cookieName="refactor-cookie-consent"
            overlay={true}
            buttonWrapperClasses="button-wrapper"
            contentClasses="container-wrapper"
            enableDeclineButton={true}
            onAccept={() => {
                setCookieConsent(true);
            }}
        >
            <div id="heading">We Value your privacy</div>
            <p>
                This website uses cookies. Cookies ensure a properly functioning
                website that adapts to your preferences. Cookies improve the
                overall ease of use of this site and personalize commercial
                messages. When you accept them, you optimize your online
                experience.
            </p>
            <span className="highlight">
                {' '}
                <Link to="privacy">
                    Privacy <span>&#38;</span> Cookie policy
                </Link>
            </span>
        </CookieConsent>
    );

    return (
        <>
            <GoogleTagManagerIFrame cookieConsent={cookieConsent} />
            <GoogleTagManagerHelmet cookieConsent={cookieConsent} />
            {cookieConsentComponent}
            {children}
            {showFooter && <Footer />}
        </>
    );
};

export default Layout;

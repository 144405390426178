import { Link } from "gatsby";
import React from "react";
import "./footer.scss";
import SharpspringSubscribe from "../sharpspringSubscribe/SharpspringSubscribe";
import Socials from "../socials/Socials";

const Footer = () => {
  return (
    <div id="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7 d-flex justify-content-center justify-content-sm-start my-3 subscribe">
            <SharpspringSubscribe classNames="my-0 px-0" />
          </div>
          <div className="col-12 col-lg mb-3 mb-lg-0 d-flex justify-content-center justify-content-sm-start align-items-center">
            <div className="d-flex flex-column text-center text-md-left">
              <div>
                <Link to="/privacy">
                  Privacy <span>&#38;</span> Cookie policy
                </Link>
              </div>
              <div>
                <Link to="/code-of-conduct">Code of conduct</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg col-md-12 mb-3 d-flex justify-content-center align-items-center d-sm-none">
            <Socials />
          </div>
        </div>
      </div>

      <div id="footer-mobile-socials" className="h-100">
        <div className="d-none d-sm-flex align-items-center h-100">
          <Socials />
        </div>
      </div>
    </div>
  );
};

export default Footer;

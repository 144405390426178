import React, {useState} from "react"
import "./sharpspringSubscribe.scss";
import {Link} from "gatsby";
import axios from 'axios';

const SharpspringSubscribe = ({classNames, resultClassNames}) => {
  const [sharpspringResult, setSharpspringResult] = useState({});
  const [email, setEmail] = useState("");

  const URL = "https://donationamount.azurewebsites.net/api/sharpspringSubscribe?code=MS53sj9J_ahDYUt0kTXP9UkOSxmnZFWR2NEUtc-wGBsHAzFu6B6J9w=="

  const _handleSubmit = async (e) => {
    e.preventDefault();
    axios.post(URL, {email: email.trim()})
    .then(res => {
      console.log(res);

      setSharpspringResult(res);
    })
  };

  const getSharpspringResultHtml = () => {
    if(sharpspringResult === undefined) return "";
    
    return (
        sharpspringResult.status === 200 && sharpspringResult.data.status === 200 
          ? <div className={"subscribe-result success " + resultClassNames}>Thank you for subscribing!</div> 
          : <div className={"subscribe-result error " + resultClassNames}>{sharpspringResult.msg}</div> 
    )
  }

  return (  
    <>
      <form id="subscribe-form" onSubmit={_handleSubmit}>
        <div className={"email-input input-group " + classNames}>
{/*          <button className="btn btn-primary tickets-button" type="button">
            <Link to="/donate">
              <span>Tickets & Donate</span>
            </Link>
          </button>*/}
          <input
            name="email"
            type="email"
            className="form-control"
            placeholder="Your email address"
            value={email}
            onChange={e => setEmail(e.target.value) }
          />
          <button className="btn btn-secondary add-on-button" type="submit">
            Stay tuned
          </button>
        </div>
        {getSharpspringResultHtml()}
      </form>
    </>
  );
};

export default SharpspringSubscribe;

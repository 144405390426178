import React, {useEffect} from "react";
import {GTM_TRACKING_ID} from '../../helpers/googleTagManager';

const GoogleTagManagerIFrame = ({cookieConsent}) => {
  
  useEffect(() => {
      if(cookieConsent === true)  appendIframeAfterBody();
  }, [cookieConsent])

  const appendIframeAfterBody = () => {
    var noScriptNode = document.createElement('noscript');
    noScriptNode.innerHTML = `<iframe
    src="https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}"
    style={{
      height: 0,
      width: 0,
      display: "none",
      visibility: "hidden",
    }}
    height="0"
    width="0"
  ></iframe>`;
    var body = document.getElementsByTagName("body")[0];
    body.insertBefore(noScriptNode, body.firstChild);
  };

  return (
    <>
    </>
  );
};

export default GoogleTagManagerIFrame;
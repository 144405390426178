import Instagram from "../../images/instagram.svg";
import Twitter from "../../images/twitter.svg";
import Facebook from "../../images/facebook.svg";
import LinkedIn from "../../images/linkedin.svg";
import InstagramDark from "../../images/instagram_green.svg";
import TwitterDark from "../../images/twitter_green.svg";
import FacebookDark from "../../images/facebook_green.svg";
import LinkedInDark from "../../images/linkedin_green.svg";
import React from "react";

const Socials = (props) => {
    return (
        <div>
            <a
                className="mr-3"
                href="https://www.instagram.com/refactor_conf/"
                target="_blank"
                rel="noopener"
            >
                <img src={props.dark ? InstagramDark : Instagram} alt="instagram logo" />
            </a>
            <a
                className="mr-2"
                href="https://twitter.com/Refactor_Conf"
                target="_blank"
                rel="noopener"
            >
                <img src={props.dark ? TwitterDark : Twitter} alt="twitter logo" />
            </a>
            <a
                className="mr-2"
                href="https://www.facebook.com/RefactorConference"
                target="_blank"
                rel="noopener"
            >
                <img src={props.dark ? FacebookDark : Facebook} alt="facebook logo" />
            </a>
            <a
                href="https://www.linkedin.com/company/re-factor-conference/"
                target="_blank"
                rel="noopener"
            >
                <img src={props.dark ? LinkedInDark : LinkedIn} alt="linkedin logo" />
            </a>
        </div>
    );
};

export default Socials;
